import 'twin.macro';
import 'styled-components/macro';
import { Route, Routes } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import LazyPages from './pages/LazyPages';
import { SpinnerPage } from './pages/SpinnerPage';
import SsoV2LoginPage from './pages/client/SsoV2LoginPage';
import AgrioSsoLoginPage from './pages/client/AgrioSsoLoginPage';
import LtiLoginPage from './pages/client/LtiLoginPage';
import MessageLoginPage from './pages/client/MessageLoginPage';
import ContactSupportPage from './pages/client/ContactSupportPage';
import {
  useFeatureFlags,
  useIdentifyLaunchDarkly,
} from './third-parties/launchdarkly';
import useCookiePreferences from './hooks/useCookiePreferences';
import useLtiGuard from './hooks/useLtiGuard';
import useLibraryHierarchy from './hooks/useLibraryHierarchy';
import { useWebsession } from './hooks/useWebsession';
import { DashboardLayout } from './components/dashboard/DashboardLayout';
import { AppAdvert } from './components/AppAdvert/AppAdvert';
import { AppAdvertBanner } from './components/AppAdvertBanner/AppAdvertBanner';
import { LoggedInUser } from './vanilla-js/websession';
import { isExclusivelyGroupReporter } from './utils/userRoles';
// import useSinglePageAccess from './hooks/useSinglePageAccess';
import useLanguage from './hooks/useLanguage';
import { NewLookBanner } from './components/NewLookBanner/NewLookBanner';

import './assets/fonts/circular/fonts/400.css';
import './assets/fonts/circular/fonts/600.css';

const PAGE_TOGGLE_STATE_MAP_STORAGE_KEY = 'pageToggleStateMap';

const AccessRoute = React.lazy(
  () => import('./common-components/AccessRoute/AccessRoute'),
);
// lazy loading for cookie banner
const CookieBanner = React.lazy(
  () => import('./components/Cookie/CookieBanner/CookieBanner'),
);

export function App() {
  const [currentUser] = useWebsession('CurrentUser');
  const flags = useFeatureFlags();

  const {
    language: { code: languageCode },
  } = useLanguage();

  const [pageToggleStateMap, setPageToggleStateMap] = useState<any>({});

  const isLoginPage = window.location.pathname.includes('login');
  const isLanguageSelectPage = window.location.pathname === '/';
  const showAppAdvert = sessionStorage.getItem('showAppAdvert');
  const isLoggedIn =
    currentUser && currentUser.Id !== -1 && currentUser.LoginOrigin;
  const isLmsUser =
    isLoggedIn && ['LTI', 'LTI13', 'RS'].includes(currentUser.LoginOrigin);
  const isNavigationVisible =
    !isLoginPage && !isLanguageSelectPage && isLoggedIn && !isLmsUser;

  const ldClient = useLDClient();

  useIdentifyLaunchDarkly(currentUser as LoggedInUser, ldClient);

  // for compatibility with vanilla-js scripts
  // do note that these are not reactive
  window.flags = flags;
  window.pageID = window.pageID ?? '';

  const { newGoodscanPage, mobileAppDownload, personalHomePage } = flags;

  useCookiePreferences();
  // useSinglePageAccess();
  useLtiGuard();
  const { config, userConfig } = useLibraryHierarchy();
  window.LibraryHierarchy = userConfig;
  window.LibraryHierarchyCfg = config;

  useEffect(() => {
    setPageToggleStateMap(
      JSON.parse(
        localStorage.getItem(PAGE_TOGGLE_STATE_MAP_STORAGE_KEY) || '{}',
      ),
    );
  }, [setPageToggleStateMap]);

  const isOptingInNewDashboardForRoute = (route: string) =>
    pageToggleStateMap[route] === true;

  const shouldRedirectDashboardOverview =
    (flags.newDashboardOverview &&
      isOptingInNewDashboardForRoute('/admin-dashboard/overview')) ||
    isExclusivelyGroupReporter();

  const getDashboardOverviewRedirectPath = () => {
    if (isExclusivelyGroupReporter()) {
      return flags.userReport
        ? '/admin-dashboard/reports/students'
        : '/dashboard/reports-group.html';
    }
    return '/admin-dashboard/overview';
  };

  return (
    <Suspense fallback={<SpinnerPage />}>
      {isNavigationVisible && (
        <g-platform-navbar
          style={{ position: 'relative', zIndex: 1000 }}
        ></g-platform-navbar>
      )}
      <Routes>
        <Route path="/" element={<LazyPages.LanguageSelectPage />} />
        <Route path="login_message.html" element={<MessageLoginPage />} />
        <Route path=":languageregion">
          <Route path="login_message.html" element={<MessageLoginPage />} />
          <Route path="login.html" element={<LazyPages.LoginPage />} />
          <Route path="login-sso" element={<SsoV2LoginPage />} />
          <Route path="agrio-sso" element={<AgrioSsoLoginPage />} />
          <Route path="login_lti.html" element={<LtiLoginPage />} />
          <Route
            path="app-download.html"
            element={<LazyPages.AppDownloadPage />}
          />
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route
              path="dashboard.html"
              element={
                <AccessRoute
                  redirectPath={getDashboardOverviewRedirectPath()}
                  isRedirected={shouldRedirectDashboardOverview}
                  isEntitled={true}
                >
                  <LazyPages.DashboardPage />
                </AccessRoute>
              }
            />
            {/* Start connect pages */}
            <Route path="connect-home.html" />
            <Route path="connect-lti.html" />
            <Route path="connect-lti13.html" />
            <Route path="connect-remote-scorm.html" />
            <Route path="connect-goodscan.html" />
            <Route path="connect-training-export.html" />
            <Route path="single-activities.html" />
            {/* End connect pages */}
            <Route
              path="organisation-home.html"
              element={
                <AccessRoute
                  isEntitled={flags.entManageOrganisation}
                  isRedirected={
                    flags.manageUsers &&
                    isOptingInNewDashboardForRoute(
                      '/admin-dashboard/organisation/users',
                    )
                  }
                  redirectPath="/admin-dashboard/organisation/users"
                >
                  <LazyPages.OrganisationHomePage />
                </AccessRoute>
              }
            />
            <Route
              path="organisation-groups.html"
              element={
                <AccessRoute
                  isEntitled={flags.entManageTeam}
                  isRedirected={
                    flags.manageGroups &&
                    isOptingInNewDashboardForRoute(
                      '/admin-dashboard/organisation/teams',
                    )
                  }
                  redirectPath="/admin-dashboard/organisation/teams"
                >
                  <LazyPages.OrganisationGroupsPage />
                </AccessRoute>
              }
            />
            <Route
              path="organisation-log.html"
              element={
                <AccessRoute
                  isEntitled={flags.entManageOrganisationLog}
                  isRedirected={
                    flags.manageActionLog &&
                    isOptingInNewDashboardForRoute(
                      '/admin-dashboard/organisation/log',
                    )
                  }
                  redirectPath="/admin-dashboard/organisation/log"
                >
                  <LazyPages.OrganisationLogPage />
                </AccessRoute>
              }
            />
            <Route
              path="organisation-roles.html"
              element={
                <AccessRoute
                  isEntitled={flags.entManageRoles}
                  isRedirected={
                    flags.manageUsers &&
                    isOptingInNewDashboardForRoute(
                      '/admin-dashboard/organisation/users',
                    )
                  }
                  redirectPath="/admin-dashboard/organisation/users"
                >
                  <LazyPages.OrganisationRolesPage />
                </AccessRoute>
              }
            />
            <Route
              path="organisation-users.html"
              element={
                <AccessRoute
                  isEntitled={flags.entManageUsers}
                  isRedirected={
                    flags.manageUsers &&
                    isOptingInNewDashboardForRoute(
                      '/admin-dashboard/organisation/users',
                    )
                  }
                  redirectPath="/admin-dashboard/organisation/users"
                >
                  <LazyPages.OrganisationUsersPage />
                </AccessRoute>
              }
            />
            <Route
              path="oscan-report.html"
              element={
                <AccessRoute
                  isEntitled={flags.entOscanAccess}
                  isRedirected={flags.oScanReportPage}
                  redirectPath="/admin-dashboard/organisation/oscan-report"
                >
                  <LazyPages.OrganisationUsersPage />
                </AccessRoute>
              }
            />
            <Route
              path="recommend-courses-home.html"
              element={
                <AccessRoute
                  isEntitled={flags.entRecommendOrganisation}
                  isRedirected={false}
                  redirectPath="/admin-dashboard/recommend"
                >
                  <LazyPages.RecommendCoursesHomePage />
                </AccessRoute>
              }
            />
            <Route
              path="recommend-courses-groups.html"
              element={
                <AccessRoute
                  isEntitled={flags.entRecommendTeam}
                  isRedirected={false}
                  redirectPath="/admin-dashboard/recommend"
                >
                  <LazyPages.RecommendCoursesGroupsPage />
                </AccessRoute>
              }
            />
            <Route
              path="recommend-courses-organisation.html"
              element={
                <AccessRoute
                  isEntitled={flags.entRecommendOrganisation}
                  isRedirected={false}
                  redirectPath="/admin-dashboard/recommend"
                >
                  <LazyPages.RecommendCoursesOrganisationPage />
                </AccessRoute>
              }
            />
            <Route
              path="reports-home.html"
              element={
                <AccessRoute
                  isEntitled={flags.entReportOrganisation}
                  redirectPath="/admin-dashboard/reports"
                  isRedirected
                >
                  <LazyPages.ReportsHomePage />
                </AccessRoute>
              }
            />
            <Route
              path="reports-course-milestones.html"
              element={
                <AccessRoute
                  isEntitled={flags.entReportMilestone}
                  redirectPath="/admin-dashboard/reports/course-milestones"
                  isRedirected
                >
                  <LazyPages.ReportsCourseMilestonesPage />
                </AccessRoute>
              }
            />
            <Route
              path="reports-course.html"
              element={
                <AccessRoute
                  isEntitled={flags.entReportCourse}
                  redirectPath="/admin-dashboard/reports/courses"
                  isRedirected={flags.dashCourseReport}
                >
                  <LazyPages.ReportsCoursePage />
                </AccessRoute>
              }
            />
            <Route
              path="reports-group-member.html"
              element={
                <AccessRoute
                  isEntitled={flags.entReportTeamMembers}
                  redirectPath="/admin-dashboard/reports/team-members"
                  isRedirected
                >
                  <LazyPages.ReportsGroupMemberPage />
                </AccessRoute>
              }
            />

            <Route
              path="reports-group.html"
              element={
                <AccessRoute
                  isEntitled={flags.entReportTeam}
                  redirectPath="/admin-dashboard/reports/students"
                  isRedirected
                >
                  <LazyPages.ReportsGroup />
                </AccessRoute>
              }
            />

            <Route
              path="reports-log.html"
              element={
                <AccessRoute
                  isEntitled={flags.entReportActivities}
                  redirectPath="/admin-dashboard/reports/detailed-users"
                  isRedirected
                >
                  <LazyPages.ReportsLogPage />
                </AccessRoute>
              }
            />
            <Route
              path="reports-student.html"
              element={
                <AccessRoute
                  isEntitled={flags.entReportIndividual}
                  redirectPath="/admin-dashboard/reports/students"
                  isRedirected
                >
                  <LazyPages.ReportsStudentPage />
                </AccessRoute>
              }
            />
            <Route
              path="resources-home.html"
              element={
                <AccessRoute
                  isEntitled={flags.entRecommendOrganisation}
                  redirectPath="/admin-dashboard/resources"
                  isRedirected={
                    flags.resources &&
                    isOptingInNewDashboardForRoute('/admin-dashboard/resources')
                  }
                >
                  <LazyPages.ResourcesHomePage />
                </AccessRoute>
              }
            />
            <Route
              path="resources-icons-leaflets.html"
              element={
                <AccessRoute
                  isEntitled={flags.entResourcesCoursesImagesLeafletsWorkouts}
                  redirectPath="/admin-dashboard/resources/icons-leaflets"
                  isRedirected={
                    flags.resources &&
                    isOptingInNewDashboardForRoute(
                      '/admin-dashboard/resources/icons-leaflets',
                    )
                  }
                >
                  <LazyPages.ResourcesIconsLeafletsPage />
                </AccessRoute>
              }
            />
            <Route
              path="resources-manuals-documentation.html"
              element={
                <AccessRoute
                  isEntitled={flags.entResourcesManualsDocuments}
                  redirectPath="/admin-dashboard/resources/manuals-docs"
                  isRedirected={
                    flags.resources && isOptingInNewDashboardForRoute('')
                  }
                >
                  <LazyPages.ResourcesManualsDocumentationPage />
                </AccessRoute>
              }
            />
            <Route
              path="resources-promo-studio.html"
              element={
                <AccessRoute
                  isEntitled={flags.entResourcesPromoStudio}
                  redirectPath="/admin-dashboard/resources/promo-studio"
                  isRedirected={
                    flags.resources &&
                    isOptingInNewDashboardForRoute(
                      '/admin-dashboard/resources/promo-studio',
                    )
                  }
                >
                  <LazyPages.ResourcesPromoStudioPage />
                </AccessRoute>
              }
            />
          </Route>
          <Route
            path="online-courses-overview.html"
            element={<LazyPages.OnlineCoursesOverviewPage />}
          />
          <Route
            path="online-courses-overview/*"
            element={<LazyPages.OnlineCoursesOverviewPage />}
          />
          {/* Only for compatibility (Remove some time after new assessments release) */}
          <Route
            path="all-assessments.html"
            element={<LazyPages.AllAssessmentsPage />}
          />
          <Route
            path="reset-password.html"
            element={<LazyPages.ResetPasswordPage />}
          />
          <Route path="gdpr.html" element={<LazyPages.GdprPage />} />
          <Route
            path="otherDevice.html"
            element={<LazyPages.OtherDevicePage />}
          />
          <Route path="ISO27001.html" element={<LazyPages.Iso27001Page />} />
          <Route
            path="all-courses.html"
            element={
              <>
                {mobileAppDownload && <AppAdvertBanner />}
                {personalHomePage && (
                  <div style={{ background: '#f7f7f7' }}>
                    <NewLookBanner href={`/${languageCode}/explore`} />
                  </div>
                )}
                <LazyPages.AllCoursesPage />
              </>
            }
          />
          <Route
            path="personal-courses.html"
            element={
              <>
                {personalHomePage && (
                  <div
                    style={{ position: 'absolute', zIndex: 99, width: '100%' }}
                  >
                    <NewLookBanner href={`/${languageCode}/home`} />
                  </div>
                )}
                <LazyPages.PersonalCoursesPage />
              </>
            }
          />
          {/* Only for compatibility (Remove some time after new assessments release) */}
          <Route
            path="assessment/:assessmentId/*"
            element={<LazyPages.AssessmentPage />}
          />
          <Route
            path="general-conditions.html"
            element={<LazyPages.GeneralConditionsPage />}
          />

          <Route
            path="goodscan.html"
            element={
              <AccessRoute isEntitled={flags.entGoodscanAccess}>
                {newGoodscanPage ? (
                  <LazyPages.ReactGoodscanPage />
                ) : (
                  <LazyPages.GoodscanPage />
                )}
              </AccessRoute>
            }
          />
          <Route
            path="goodscan/:activityId"
            element={
              <AccessRoute isEntitled={flags.entGoodscanAccess}>
                {newGoodscanPage ? (
                  <LazyPages.ReactSimpleGoodscanPage />
                ) : (
                  <LazyPages.SimpleGoodscanPage />
                )}
              </AccessRoute>
            }
          />

          <Route
            path="online-course/:courseId/*"
            element={<LazyPages.CoursePage />}
          />
          <Route
            path="course/:courseId/:version"
            element={<LazyPages.SimpleCoursePage />}
          />
          <Route
            path="notifications.html"
            element={<LazyPages.NotificationsPage />}
          />
          <Route
            path="privacy-statements.html"
            element={<LazyPages.PrivacyStatementsPage />}
          />
          <Route
            path="user-agreements.html"
            element={<LazyPages.UserAgreementsPage />}
          />
          <Route path="action">
            <Route
              path="reset-password.html"
              element={<LazyPages.ResetPasswordOldPage />}
            />
            <Route
              path="ActivateAccount.html"
              element={<LazyPages.ActivateAccountPage />}
            />
            <Route path="activate.html" element={<LazyPages.ActivatePage />} />
            <Route
              path="ActivateVoucher.html"
              element={<LazyPages.ActivateVoucherPage />}
            />
          </Route>
          <Route path="my-account">
            <Route
              path="my-dashboard.html"
              element={<LazyPages.MyDashboardPage />}
            />
            <Route
              path="personal-info.html"
              element={<LazyPages.PersonalInfoPage />}
            />
          </Route>
          <Route path="cookies">
            <Route
              path="cookie-settings.html"
              element={<LazyPages.CookieSettingsPage />}
            />
          </Route>
          <Route
            path="online-courses.html"
            element={<LazyPages.OnlineCoursesPage />}
          />
          <Route
            path="contact-and-support.html"
            element={<ContactSupportPage />}
          />
          <Route path="logoff.html" element={<LazyPages.LogoffPage />} />
        </Route>
        <Route path="mail.html" element={<LazyPages.MailPage />} />
        <Route path="mailing.html" element={<LazyPages.MailingPage />} />
        <Route path="*" element={<LazyPages.NotFoundPage />} />
      </Routes>
      {!showAppAdvert && !isLoginPage && mobileAppDownload && <AppAdvert />}
      <CookieBanner />
    </Suspense>
  );
}
